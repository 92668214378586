import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { I18nextProvider } from 'react-i18next';

import i18n from './utils/i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Layout } from './components';
import { Homepage, AboutPage, ContactPage, FaqPage, CarpetCleaningPage, UpholsteryCleaningPage, MattressCleaningPage, MaintenancePage, UrinePetOdourRemoverPage, CommercialCarpetCleaningPage, HardFloorCleaningPage, EndOfTenancyCleaningPage, HolidayLetCleaningPage } from './pages';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Router>
        <Layout>
          <Routes>
            <Route path='/' element={<Homepage />}/>
            <Route path='/about' element={<AboutPage />}/>
            <Route path='/contact' element={<ContactPage />}/>
            <Route path='/faq' element={<FaqPage />}/>
            <Route path='/services/carpet-cleaning' element={<CarpetCleaningPage />}/>
            <Route path='/services/upholstery-cleaning' element={<UpholsteryCleaningPage />}/>
            <Route path='/services/mattress-cleaning' element={<MattressCleaningPage />}/>
            <Route path='/services/maintenance' element={<MaintenancePage />}/>
            <Route path='/services/urine-pet-odour-remover' element={<UrinePetOdourRemoverPage />}/>
            <Route path='/services/commercial-carpet-cleaning' element={<CommercialCarpetCleaningPage />}/>
            <Route path='/services/hard-floor-cleaning' element={<HardFloorCleaningPage />}/>
            <Route path='/services/end-of-tenancy-cleaning' element={<EndOfTenancyCleaningPage />}/>
            <Route path='/services/holiday-let-cleaning' element={<HolidayLetCleaningPage />}/>
          </Routes>
        </Layout>
      </Router>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// TODO: add unit tests for all the components and pages
// TODO: remove sendgrid account - in progress, on june 1st
// TODO: fix lint issues