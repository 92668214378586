import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'react-bootstrap';

import { Hero, PageContainer } from '../../components';
import { textWithNewLine } from '../../utils/functions';
import './AboutPage.scss';

function AboutPage() {
  const { t } = useTranslation();

  return (
    <div className='main-content'>
      <Hero className='about-hero'>
        <h1>{t('ABOUT_US_TITLE')}</h1>
        <p>{t('ABOUT_INTRO')}</p>
      </Hero>
      <PageContainer>
        <Row className='about-story-section page-img-section'>
          <Col md={7}>
            <div className='text'>
              <h3>{t('OUR_STORY_TITLE')}</h3>
              <br />
              <p>{textWithNewLine(t('OUR_STORY_TEXT'))}</p>
            </div>
          </Col>
          <Col md={5}>
            <div className='image'>
              <img src='/img/about-us.jpeg' alt='About-us' />
            </div>
          </Col>
        </Row>
        <Row className='about-where-section page-section'>
          <h4>{t('WHERE_TITLE')}</h4>
          <br />
          <p>{textWithNewLine(t('WHERE_TEXT'))}</p>
        </Row>
        <Row className='about-contact-section back-page-section'>
          <div className='text'>
            <img src='/img/confused.png' alt='Confused-icon' className='about-icon-class' />
            <h3>{t('YOUR_TOWN_TITLE')}</h3>
            <br />
            <p>{t('YOUR_TOWN_TEXT')}</p>
            <br />
            <div className='about-contact-section-button'>
              <Button href='tel:07477013710' className='btn-light'>{t('PHONE_NUMBER')}</Button>
              <Button href='/contact' className='btn-dark'>{t('GET_A_QUOTE')}</Button>
            </div>
            <br />
          </div>
        </Row>
        <Row className='about-certificate-section page-img-section'>
          <Col md={7}>
            <div className='text'>
              <h3>{t('NCAA_TITLE')}</h3>
              <br />
              <p>{textWithNewLine(t('NCAA_TEXT'))}</p>
            </div>
          </Col>
          <Col md={5}>
            <div className='image'>
              <img src='/img/certificate.jpeg' alt='NCAA-cert' />
            </div>
          </Col>
        </Row>
      </PageContainer>
    </div>
  )
}

export default AboutPage;