import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import './Footer.scss';
import { services } from '../../utils/constants';

function Footer () {
  const { t } = useTranslation();

  function displayServiceLinks() {
    return services.map((service, index) => (
      <a key={index} href={`/services/${service.toLowerCase()}`} className='footer-link'>
        {t(`${service}_TITLE`)}
      </a>
    ));
  }

  return (
    <footer className='footer'>
      <Container className='footer-container'>
        <Row className='justify-content-sm-center g-5'>
          <Col md='3'>
            <div className='footer-content'>
              <a href='https://trustedlocalcleaners.ncca.co.uk/listing/green-carpet-care/' target='_blank' rel='noopener noreferrer'>
                <img src='/img/ncaa-logo-removebg.png' alt='NCCA logo' className='footer-ncaa-logo' />
              </a>
            </div>
          </Col>
          <Col md='auto'>
            <div className='footer-content'>
              <h3 className='footer-head'>{t('FOOTER_CONTACT_DETAILS')}</h3>
              <div className='footer-links'>
                <a href='mailto:info@greencarpetcare.co.uk'><FontAwesomeIcon icon={faEnvelope} /> {t('EMAIL_ADDRESS')}</a>
                <a href='tel:07477013710'><FontAwesomeIcon icon={faPhone} /> {t('PHONE_NUMBER')}</a>
              </div>
              <div className='social-media-links'>
                <a href='https://www.facebook.com/profile.php?id=61558330046817' target='_blank' rel='noreferrer noopener'>
                  <FontAwesomeIcon icon={faFacebook} size='2xl' />
                </a>
                <a href='https://www.instagram.com/green_carpet_care_?igsh=MXRtYXVrMDFkcjN1NQ==' target='_blank' rel='noreferrer noopener'>
                  <FontAwesomeIcon icon={faInstagram} size='2xl' />
                </a>
              </div>
            </div>
          </Col>
          <Col md='auto'>
            <div className='footer-content'>
              <h3 className='footer-head'>{t('SERVICES')}</h3>
              <div className='footer-links'>
                {displayServiceLinks()}
              </div>
              <br />
            </div>
          </Col>
          <Col md='auto'>
            <div className='footer-content'>
              <h3 className='footer-head'>{t('FOOTER_QUICK_LINKS')}</h3>
              <div className='footer-links'>
                <a href='/'>{t('HOME')}</a>
                <a href='/about'>{t('ABOUT')}</a>
                <a href='/contact'>{t('CONTACT')}</a>
                <a href='/faq'>{t('FAQ')}</a>
                <a href='/reviews' onClick={(e) => e.preventDefault()} className='disabled'>{t('REVIEWS')}</a>
              </div>
              <br />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;