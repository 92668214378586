import React from 'react';

import { ServicePageContainer } from '../../../components';
import { services } from '../../../utils/constants';
import './UrinePetOdourRemoverPage.scss';

function UrinePetOdourRemoverPage() {
  return (
    <ServicePageContainer service={services[4]} />
  );
}

export default UrinePetOdourRemoverPage;