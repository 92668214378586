import React from 'react';

import { ServicePageContainer } from '../../../components';
import { services } from '../../../utils/constants';
import './HardFloorCleaningPage.scss';

function HardFloorCleaningPage() {
  return (
    <ServicePageContainer service={services[6]} />
  );
}

export default HardFloorCleaningPage;