import React from 'react';

import { ServicePageContainer } from '../../../components';
import { services } from '../../../utils/constants';
import './MaintenancePage.scss';

function MaintenanceCleaningPage() {
  return (
    <ServicePageContainer service={services[3]} />
  );
}

export default MaintenanceCleaningPage;