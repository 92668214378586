import React from 'react';

import { ServicePageContainer } from '../../../components';
import { services } from '../../../utils/constants';
import './CarpetCleaningPage.scss';

function CarpetCleaningPage() {
  return (
    <ServicePageContainer service={services[0]} />
  );
}

export default CarpetCleaningPage;