import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { services } from '../../utils/constants';
import './Header.scss';

function Header() {
  const { t } = useTranslation();
  const location = useLocation();

  function displayNavServices() {
    return services.map((service, index) => {
      return (
        <NavDropdown.Item key={index} href={`/services/${service.toLowerCase()}`}>{t(`${service}_TITLE`)}</NavDropdown.Item>
      );
    })
  }

  return (
    <Navbar collapseOnSelect bg='light' expand='md' className='sticky-top'>
      <Container>
        <Navbar.Brand href='/'>
          <img src='/img/logo.png' alt='logo' className='header-desktop-logo' />
          <img src='/img/logo-notext.png' alt='logo-notext' className='header-mobile-logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar' />
        <Navbar.Collapse className='responsive-navbar'>
          <Nav className='me-auto' activeKey={location.pathname}>
            <Nav.Link href='/' eventKey='/'>{t('HOME')}</Nav.Link>
            <Nav.Link href='/about' eventKey='/about'>{t('ABOUT')}</Nav.Link>
            <NavDropdown title={t('SERVICES')} id='basic-nav-dropdown'>
              {displayNavServices()}
            </NavDropdown>
            <Nav.Link href='/contact' eventKey='/contact'>{t('CONTACT')}</Nav.Link>
            <Nav.Link href='/faq' eventKey='/faq'>{t('FAQ')}</Nav.Link>
            <Nav.Link href='/reviews' eventKey='/reviews' disabled>{t('REVIEWS')}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;