import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { responsive, services } from '../../utils/constants';
import { displayFlipPanels, textWithNewLine } from '../../utils/functions';
import { Hero, PageContainer, ServiceBox } from '../../components';
import './HomePage.scss';

function Homepage() {
  const { t } = useTranslation();

  function displayServiceBox() {
    return services.map((service, index) => {
      return (
        <div className='home-slider' key={index}>
          <ServiceBox service={service} key={index} />
        </div>
      );
    })
  }

  return (
    <div className='main-content'>
      <Hero>
        <h1>{t('COMPANY_NAME')}</h1>
        <br />
        <p>{t('HOME_WELCOME')}</p>
        <br />
        <div className='home-hero-button-container'>
          <Button href='/contact'>{t('GET_A_QUOTE')}</Button>
          <Button href='tel:07477013710'>{t('PHONE_NUMBER')}</Button>
        </div>
      </Hero>
      <PageContainer>
        <Row className='home-mission-section page-section'>
          <h4>{t('HOME_MISSION_TITLE')}</h4>
          <br />
          <p>{t('HOME_MISSION_TEXT')}</p>
        </Row>
        <Row className='justify-content-md-center home-services-section'>
          <Col md={12}>
            <h2>{t('OUR_SERVICES')}</h2>
            <Carousel
              responsive={responsive}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              partialVisible={false}
              dotListClass='custom-dot-list-style'
            >
              {displayServiceBox()}
            </Carousel>
          </Col>
        </Row>
        <Row className='home-why-section page-img-section'>
          <Col md={7}>
            <div className='text'>
              <h3>{t('HOME_WHY_TITLE')}</h3>
              <br />
              <p><span className='highlight'>{t('NATURE_INSPIRED_CLEANING')}</span>{t('NATURE_INSPIRED_CLEANING_TEXT')}</p>
              <p><span className='highlight'>{t('LOCAL_EXPERTISE')}</span>{t('LOCAL_EXPERTISE_TEXT')}</p>
              <p><span className='highlight'>{t('COMPREHENSIVE_SERVICES')}</span>{t('COMPREHENSIVE_SERVICES_TEXT')}</p>
              <p><span className='highlight'>{t('SAFE_NON_TOXIC')}</span>{t('SAFE_NON_TOXIC_TEXT')}</p>
              <p><span className='highlight'>{t('BUDGET_FRIENDLY_PRICES')}</span>{t('BUDGET_FRIENDLY_PRICES_TEXT')}<span className='highlight'>{t('UNBEATBLE_PRICE')}</span>.</p>
            </div>
          </Col>
          <Col md={5}>
            <div className='image'>
              <img src='/img/front-car.jpg' alt='Why-img' />
            </div>
          </Col>
        </Row>
        <Row className='home-about-section back-page-section'>
          <Col md={7}>
            <div className='text'>
              <h3>{t('HOME_ABOUT_TITLE')}</h3>
              <br />
              <p>{textWithNewLine(t('HOME_ABOUT_TEXT'))}</p>
              <br />
              <div className='about-section-button'>
                <Button href='/about' className='btn-light'>{t('MORE_ABOUT')}</Button>
              </div>
              <br />
            </div>
          </Col>
        </Row>
        <Row className='work-section page-section'>
          <Col md={12}>
            <div className='content'>
              <h3>{t('OUR_WORK_TITLE')}</h3>
              <div className='flipping-panels'>
                {displayFlipPanels()}
              </div>
            </div>
          </Col>
        </Row>
      </PageContainer>
    </div>
  );
}

export default Homepage;