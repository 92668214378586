import React from 'react';

import './PageContainer.scss';

function PageContainer({ children }) {
  return (
    <div className='page-container'>
      {children}
    </div>
  );
}

export default PageContainer;