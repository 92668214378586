import React from 'react';

import './Hero.scss';

function Hero({ children, className = '' }) {
  return (
    <div className={`hero ${className}`} data-testid='hero'>
      <div className='hero-text'>
        {children}
      </div>
    </div>
  );
}

export default Hero;