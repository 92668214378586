import React from 'react';

import { Header, Footer } from '../index';

function Layout ({ children }) {
  return (
    <div>
      <Header />
      <div className='content'>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
