import React from 'react';
import { Row, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Hero, PageContainer } from '../../components';
import './FaqPage.scss';

function FaqPage() {
  const { t, ready } = useTranslation();

  function displayQuestions() {
    if (!ready) return 'loading translations...'

    const questions = t('QUESTIONS', { returnObjects: true });
    if (Array.isArray(questions) && questions.length > 0) { 
      return (
        <Accordion>
          {questions.map((question, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{t(question.title)}</Accordion.Header>
              <Accordion.Body>{t(question.answer)}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )
    } else {
      return ''
    }
  }

  return (
    <div className='main-content'>
      <Hero className='faq-hero'>
        <h1>{t('FAQ_TITLE')}</h1>
        <p>{t('FAQ_TEXT')}</p>
      </Hero>
      <PageContainer>
        <Row className='page-section faq-intro-section'>
          <h4>{t('FAQ_WELCOME_TITLE')}</h4>
          <p>{t('FAQ_WELCOME_TEXT_1')} <a href='/contact'>{t('CONTACT_PAGE_TEXT')}</a>{t('FAQ_WELCOME_TEXT_2')}<a href='mailto:info@greencarpetcare.co.uk'>{t('EMAIL_ADDRESS')}</a>{t('FAQ_WELCOME_TEXT_3')}<a href='tel:07477013710'>{t('PHONE_NUMBER')}</a>{t('FAQ_WELCOME_TEXT_4')}</p>
        </Row>
        <Row className='faq-section page-section'>
          {displayQuestions()}
        </Row>
      </PageContainer>
    </div>
  )

}

export default FaqPage;