import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Hero, PageContainer } from '../';
import { displayFlipPanels, textWithNewLine } from '../../utils/functions';
import './ServicePageContainer.scss';

function ServicePageContainer({ service }) {
  const { t, ready } = useTranslation();

  function displayBenefits() {
    if (!ready) return 'loading translations...'
    
    const benefits = t(`${service}_BENEFITS`, { returnObjects: true });
    if (Array.isArray(benefits) && benefits.length > 0) {
      return (
        <ul className='benefits-list'>
          {benefits.map((benefit, index) => (
            <li key={index}><span className='highlight'>{t(benefit.title)}</span>{t(benefit.text)}</li>
          ))}
        </ul>
      )
    } else {
      return ''
    }
  }

  return (
    <div className='main-content'>
      <Hero className={`${service.toLowerCase()}-hero service-hero`}>
        <h1>{t(`${service}_TITLE`)}</h1>
        <p>{t('ALL_INFO_ABOUT')}{t(`${service}_TITLE`)}</p>
      </Hero>
      <PageContainer>
        <Row className={`page-section service-intro-section ${service.toLowerCase()}`}>
          <h4>{t(`${service}_INTRO_TITLE`)}</h4>
          <br />
          <p>{textWithNewLine(t(`${service}_INTRO_TEXT`))}</p>
        </Row>
        <Row className='page-section service-process-section'>
          <h3>{t('CLEANING_PROCESS')}</h3>
          <div className='process-image'>
            <img src='/img/process.png' alt='Process' />
          </div>
        </Row>
        <Row className={`page-section service-benefits-section ${service.toLowerCase()}`}>
          <br />
          <h3>{t('BENEFITS_OF')}{t(`${service}_TITLE`)}</h3>
          <br />
          {displayBenefits()}
        </Row>
        <Row className='service-prices-section page-img-section'>
          <Col md={7}>
            <div className='text'>
              <h3>{t('PRICES_TITLE')}</h3>
              <br />
              <p>{textWithNewLine(t('PRICES_INTRO'))}</p>
              <img src='/img/no-guarantee.jpeg' alt='No-guarantee' />
            </div>
          </Col>
          <Col md={5}>
            <div className='image'>
              <img src='/img/prices.jpeg' alt='Prices' />
            </div>
          </Col>
        </Row>
        <Row className='work-section page-section service-work-section'>
          <Col md={12}>
            <div className='content'>
              <br />
              <h3>{t('OUR_WORK_TITLE')}</h3>
              <p>{t('OUR_WORK_INTRO')}</p>
              <div className='flipping-panels'>
                {displayFlipPanels()}
              </div>
            </div>
          </Col>
        </Row>
      </PageContainer>
    </div>
  );
}

export default ServicePageContainer;