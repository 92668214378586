import React from 'react'
import { FlippingPanel } from '../components'

export function displayFlipPanels() {
  return Array.from({ length: 5 }).map((_, index) => (
    <FlippingPanel 
      key={index} 
      frontPhoto={`/img/before${index + 1}.jpeg`} 
      backPhoto={`/img/after${index + 1}.jpeg`} 
    />
  ))
}

export function textWithNewLine(translation) {
  return translation.split('\n').map((item, key) => {
    return <span key={key} data-testid='new-line-text'>{item}<br/></span>
  });
}