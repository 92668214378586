import React, { useState } from 'react';

import './FlippingPanel.scss';

function FlippingPanel(props) {
  const [isFlipped, setIsFlipped] = useState(false);

  const { frontPhoto, backPhoto } = props;

  return (
    <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} data-testid='flip-card'>
      <div className='flip-card-inner' onClick={() => setIsFlipped(!isFlipped)}>
        <div className='flip-card-front'>
          <div className='card-content'>
            <button className='flip-button'><img src={frontPhoto} alt='Front' className='front-photo' /></button>
          </div>
        </div>
        <div className='flip-card-back'>
          <div className='card-content'>
            <button className='flip-button'><img src={backPhoto} alt='Back' className='back-photo' /></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlippingPanel;