import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './ServiceBox.scss';
import { textWithNewLine } from '../../utils/functions';

function ServiceBox( { service } ) {
  const { t } = useTranslation();

  return (
    <Container className='service-box'>
      <Row>
        <Col xs={12}>
          <Row className='head-row'>
            <Col xs={12}>
              <h3>{t(`${service}_TITLE`)}</h3>
              <img src={`/img/${service}.png`} alt={`${service} icon`} className='service-box-icon-class' /> 
            </Col>
          </Row>
          <p>{textWithNewLine(t(`${service}_DESCRIPTION`))}</p>
          <div className='service-box-button'>
            <Button variant='primary' className='btn-dark' href={`/services/${service.toLowerCase()}`}>{t('FIND_OUT_MORE')}</Button>
          </div>
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceBox;