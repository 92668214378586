import React from 'react';

import { ServicePageContainer } from '../../../components';
import { services } from '../../../utils/constants';
import './MattressCleaningPage.scss';

function MattressCleaningPage() {
  return (
    <ServicePageContainer service={services[2]} />
  );
}

export default MattressCleaningPage;