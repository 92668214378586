import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from '@emailjs/browser';

import { Hero, PageContainer } from '../../components';
import './ContactPage.scss';

function ContactPage() {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (form) => {
    setIsLoading(true);

    const formData = new FormData(form);

    const emailToSend = {
      formName: formData.get('formName'),
      formEmail: formData.get('formEmail'),
      formPhone: formData.get('formPhone'),
      formPostcode: formData.get('formPostcode'),
      formMessage: formData.get('formMessage')
    }

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, emailToSend, {
      publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    })
      .then(
        () => {
          alert(t('EMAIL_SENT'));
          form.reset();
          setValidated(false);
        },
        (error) => {
          alert(t('EMAIL_ERROR'));
        },
      ).finally(() => {
        setIsLoading(false)
      });
  }

  const handleSubmit = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      sendEmail(form)
    }
  };

  return (
    <div className='main-content'>
      <Hero className='contact-hero'>
        <h1>{t('CONTACT_US')}</h1>
        <p>{t('CONTACT_US_TEXT')}</p>
      </Hero>
      <PageContainer>
        <Row className='contact-form-section back-page-section'>
          <Col md={6} className='contact-form'>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId='formName' className='mb-4'>
                <Form.Control type='text' placeholder='Enter name' name='formName' required />
              </Form.Group>

              <Form.Group controlId='formEmail' className='mb-4'>
                <Form.Control type='email' placeholder='Enter email' name='formEmail' required />
                <Form.Control.Feedback type='invalid'>
                  {t('VALID_EMAIL_MESSAGE')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId='formPhone' className='mb-4'>
                <Form.Control 
                  type='text' 
                  placeholder='Enter phone number' 
                  required 
                  pattern='[0-9\(\)\#\+\-]+'
                  name='formPhone'
                />
                <Form.Control.Feedback type='invalid'>
                  {t('VALID_PHONE_MESSAGE')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId='formPostcode' className='mb-4'>
                <Form.Control type='text' placeholder='Enter postcode' name='formPostcode' required />
              </Form.Group>

              <Form.Group controlId='formMessage' className='mb-4'>
                <Form.Control as='textarea' rows={3} placeholder='Enter message' name='formMessage' required/>
              </Form.Group>

              <Button type='submit' className='mb-4 btn-dark' disabled={isLoading}>
                {t('SEND_BUTTON')}
              </Button>
            </Form>
          </Col>
          <Col md={6} className='contact-info'>
            <p>{t('CONTACT_MESSAGE_1')}</p>
            <br />
            <p>{t('CONTACT_MESSAGE_2')}</p>
            <br />
            <p><FontAwesomeIcon icon={faEnvelope} /> {t('EMAIL_ADDRESS')} <br />
              <FontAwesomeIcon icon={faPhone} /> {t('PHONE_NUMBER')} <br />
              <FontAwesomeIcon icon={faLocationDot} /> {t('ADDRESS')}<br /></p>
            <div className='social-media-links'>
              <a href='https://www.facebook.com/profile.php?id=61558330046817' target='_blank' rel='noreferrer noopener'>
                <FontAwesomeIcon icon={faFacebook} size='2xl' />
              </a>
              <a href='https://www.instagram.com/green_carpet_care_?igsh=MXRtYXVrMDFkcjN1NQ==' target='_blank' rel='noreferrer noopener'>
                <FontAwesomeIcon icon={faInstagram} size='2xl' />
              </a>
            </div>
          </Col>
        </Row>
      </PageContainer>
    </div>
  )
}

export default ContactPage;