import React from 'react';

import { ServicePageContainer } from '../../../components';
import { services } from '../../../utils/constants';
import './UpholsteryCleaningPage.scss';

function UpholsteryCleaningPage() {
  return (
    <ServicePageContainer service={services[1]} />
  );
}

export default UpholsteryCleaningPage;